<template lang="pug">
    Layout
        div
</template>

<script>
    import Layout from '../layouts/main'
    import constructModalSettings from 'mixins/modalSettings'
    import formValidator from 'mixins/formValidator'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: "Export",

        components: {
            Layout,
        },

        mixins: [
            constructModalSettings,
            formValidator,
        ],

        props: {
            exportID: {
                type: String,
                required: true,
            },
        },

        computed: {
            ...mapGetters([
                'currentCustomer',
                'currentCompany',
            ]),
        },

        async mounted () {
            try {
                let res = await this.getExportFile()

                let blob = new Blob([res.data], { type: 'application/octet-stream' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `LifeBooster_Risk-data-export_${res.name.split('=').pop()}`
                link.target = '_blank'
                link.setAttribute("type", "hidden")

                document.body.appendChild(link)

                link.click()
                link.remove()

                this.successModal(
                    this.$t('exportPage.exportComplete'),
                )

                this.closeModal()
            
            } catch (error) {
                this.$store.dispatch('closeModal')
                let msg = this.$t("exportPage.exportError")

                if(error.message === constants.HTTP_RESPONSE_CODES.GONE) {
                    msg = this.$t("exportPage.exportExpired")
                }
                else if(error.message === constants.HTTP_RESPONSE_CODES.NOT_FOUND
                    || error.message === constants.HTTP_RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
                    msg = this.$t("exportPage.exportFailed")
                }

                this.failModal(
                    this.$t(msg),
                    this.$t("somethingWentWrong")
                )
            }
        },

        methods: {

            closeModal () {
                setTimeout(() => {
                    this.$store.dispatch('closeModal')
                }, 3000)
            },

             async getExportFile() {
                return await this.$store.dispatch(
                    'getExportFile',
                    {
                        currentCustomer: this.currentCustomer,
                        currentCompany: this.currentCompany,
                        exportID: this.exportID,
                    },
                )
            },
        },
    }
</script>

<style lang="scss" scoped></style>